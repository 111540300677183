/**
 * Keys for referencing features. These will be used in URL parameters, so they have to be
 * camelCased and not contain any special characters.
 *
 * IMPORTANT: The features cannot contain any CONTENT. I.e. do not make a feature for "PAGE_TITLE"
 * or anything like that.
 *
 * IMPORTANT: All features (except the test one) need to have an expiration date and need to be
 * cleaned up after being 100% turned on.
 *
 * See: https://wiki.sc-corp.net/pages/viewpage.action?pageId=255335468
 */
export enum Feature {
  /**
   * Feature to enable the chat widget on Avalon.
   *
   * Expiration date: 2024-11-01
   */
  AVALON_ENABLE_CHAT_WIDGET = 'avalonEnableChatWidget',

  /** Feature flag to redirect users to the new Job Detail Page from the Job Openings page */
  CAREERS_ENABLE_JOB_DETAIL_PAGE = 'careersEnableJobDetailPage',

  /** Feature flag to control using web client id for experiementation bucketing */
  COOKIE_BASED_BUCKETING = 'cookiesBasedBucketing',

  /**
   * Feature flag to control lazy loading of logging framework
   *
   * Purpose: Impact measurement of lazy loading feature on site performance and measured click
   * through rate
   *
   * Expiration date: 8/4/2023
   */
  DISABLE_LAZY_LOAD_LOGGING = 'disableLazyLoadLogging',

  /**
   * Flag to enable block, blockHero, navigator and page to pull media from custom Image and Video
   * references
   *
   * Expiration: 2024-04-01
   */
  ENABLE_DEVICE_SPECIFIC_MEDIA = 'enableDeviceSpecificMedia',

  /**
   * Feature to enable HLS playback for video
   *
   * Expiration date: 2025-03-01
   */
  ENABLE_HLS_PLAYBACK = 'enableHlsPlayback',

  /** Flag to use enable FR and UK stores Expiration: 2023-09-20 */
  ENABLE_PIXY_EUROPEAN_STORES = 'enablePixyEuropeanStores',

  /**
   * Feature to enable the site search
   *
   * Expiration date: Never
   */
  ENABLE_SITE_SEARCH = 'enableSiteSearch',

  /**
   * Flag to disable ASL stream for SPS. Purpose: In case ASL stream is broken. Expiration:
   * 2023-04-20
   */
  EXPERIENCE_ENABLE_ASL_STREAM = 'spsEnableAslStream',

  /**
   * Flag to disable live stream captions for SPS. Purpose: In case captioning on the event is
   * inaccurate or malfunctioning. Expiration: 2023-04-20
   */
  EXPERIENCE_ENABLE_CAPTIONS = 'spsEnableCaptions',

  /** Flag to enable the global nav items to be positioned on the left/center/right of the nav */
  GLOBAL_NAV_ITEMS_POSITION = 'globalNavItemsPosition',

  /**
   * Feature flag to enable forcing URL redirect of home to /
   *
   * Purpose: Snapchat A/B testing redirects to /home but wants it to look like /
   * https://jira.sc-corp.net/browse/ENTWEB-6809
   *
   * Expiration date: 19th Dec. 2022 Links: N/A
   */
  HOME_REDIRECT = 'homeRedirect',

  /**
   * Feature to scroll to anchor matching url hash. Feature gated to be able to fix bugs found in
   * prod because this behavior is hard to test for in the wild. Expiration date: 2023-04-01 After
   * this we can keep it on permanently. Links: N/A
   */
  SCROLL_TO_ANCHOR = 'scrollToAnchor',
  /**
   * Flag to show the go to stream button on the virtual registration confirmation page. Purpose:
   * Redirect users who register on event day to the page where the event stream will be.
   * Expiration: 2023-04-20
   */
  SPS_SHOW_GO_TO_STREAM_BUTTON = 'spsShowGoToStreamButton',

  /** Flag to use custom Hls.js powered player for SPS as opposed to AMP. Expiration: 2023-10-01 */
  SPS_USE_EVENT_PLAYER = 'spsUseEventPlayer',

  /**
   * This is a sample feature flag.
   *
   * Purpose: For testing. Expiration date: Never. Links: N/A
   */
  TEST_FEATURE = 'testFeatureFlag',

  /**
   * Feature to change the current chart toggle buttons for dropdowns
   *
   * Expiration date: 2025-05-01
   */
  USE_CHART_DROPDOWN_TOGGLE = 'useChartDropdownToggle',

  /**
   * Feature to use Content Accordion items (Content entries)
   *
   * Expiration date: 2025-01-01
   */
  USE_CONTENT_ACCORDION_ITEMS = 'useContentAccordionItems',

  /**
   * Feature to use new mega-menu design
   *
   * Expiration date: 2025-01-01
   */
  USE_MEGA_MENU = 'useMegaMenu',

  /**
   * Feature to use snap motif
   *
   * Expiration date: 2025-03-31
   */
  USE_SNAP_MOTIF = 'useSnapMotif',
}

/**
 * Values for the feature flags.
 *
 * Note that these have to be strings because they are set in text fields and through the URL
 * parameters.
 *
 * The values can technically be anything that can be serialized/deserialized.
 *
 * For booleans use String(value) and parse using value === 'true' For numbers, use String(value)
 * and parse using Number(value) For objects use JSON.stringify(value) and parse using
 * JSON.parse(value)
 */
export type FeatureFlags = Record<Feature, string>;
